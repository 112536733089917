import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setActiveModal } from '../../../redux/app-global';
import { EAppModal } from '../../../redux/app-global/app-global.types';
import { FaInstagram, FaLinkedin, FaTimes, FaTwitter } from 'react-icons/fa';
import intro from './intro.json';

const AboutUsPage = () => {
  const dispatch = useDispatch();

  // const activeModal = useSelector(
  //   (state: RootState) => state.appGlobal.activeModal,
  // );

  const currentContent = useSelector(
    (state: RootState) => state.appGlobal.currentContent
  );



  // const handleOpenFigma = () => {
  //   window.open('https://www.figma.com/proto/xBKHBDfsKkB33mM7uDL4Nb/cloudframe-demo?node-id=112-746&t=vqgniDVnozp7H0z0-1&scaling=min-zoom&content-scaling=fixed&page-id=67%3A108&starting-point-node-id=112%3A746', '_blank');
  // };

  // const handleCloseModal = () => {
  //   dispatch(setActiveModal(null));
  // };

  return (
    <div className="container max-w-[80%] mx-auto">
      {/* {activeModal === EAppModal.ABOUT_US && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-80">
          <div className="relative overflow-auto w-full max-w-3xl h-auto border border-gray-300 rounded-lg bg-white shadow-xl p-8">
            <div className="absolute top-4 right-4">
              <button
                onClick={handleCloseModal}
                className="flex items-center justify-center w-10 h-10 bg-gray-100 text-gray-600 hover:bg-red-500 hover:text-white rounded-full transition-all duration-300 ease-in-out focus:outline-none shadow-lg"
                aria-label="Close modal"
              >
                <FaTimes size={20} />
              </button>
            </div>
            <h2 className="text-center text-3xl font-semibold mt-2 mt-6">
              {intro.modalContent.header}
            </h2>
            <br />
            <div className="space-y-4">
              <p className="text-gray-700 text-lg leading-relaxed">
                {intro.modalContent.description1}
              </p>
              <p className="text-gray-700 text-lg leading-relaxed font-bold">
                {intro.modalContent.callToAction}
              </p>
            </div>
            <div className="flex justify-center mt-8">
              <button
                onClick={handleOpenFigma}
                className="bg-blue-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-600 transition duration-200 ease-in-out"
              >
                <span className="font-bold">Explore Demo</span>
              </button>
            </div>
          </div>
        </div>
      )} */}
      <div className="mt-4 gap-4 text-justify">
        <h1 className="text-left text-3xl font-semibold mt-2 mt-6">
          {currentContent?.heading}
        </h1>
        <h1 className="text-3xl text-grey20 mb-4">
          ----------------------------------------------
        </h1>
        <p className="mb-2">{currentContent?.description1}</p>
        <p className="mb-2">{currentContent?.description2}</p>
        <p className="mb-2">{currentContent?.description3}</p>
        <p className="mb-2">{currentContent?.description4}</p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mt-4 mb-[5%]">
          <div className="px-4 py-4 bg-background rounded-sm shadow-sm">
            {currentContent?.cardTitle1}
          </div>
          <div className="px-4 py-4 bg-background rounded-sm shadow-sm">
            {currentContent?.cardTitle2}
          </div>
          <div className="px-4 py-4 bg-background rounded-sm shadow-sm">
            {currentContent?.cardTitle3}
          </div>
          <div className="px-4 py-4 bg-background rounded-sm shadow-sm">
            {currentContent?.cardTitle4}
          </div>
          <div className="px-4 py-4 bg-background rounded-sm shadow-sm">
            {currentContent?.cardTitle5}
          </div>
          <div className="px-4 py-4 bg-background rounded-sm shadow-sm">
            {currentContent?.cardTitle6}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
