import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Home } from './pages/home/home';
import { SharedService } from './services/shared-service';

export enum ROUTES {
  HOME = '/',
}

const onPremRoutes = [
  {
    path: ROUTES.HOME,
    element: <Home />,
  },
];

export default function AppNavigation() {
  const router = createBrowserRouter(
    SharedService.isOnPrem
      ? onPremRoutes
      : [
          {
            path: '*',
            element: <Home />,
          },
        ],
  );

  return <RouterProvider router={router} />;
}
